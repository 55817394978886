import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AppBar, Layout } from 'react-admin';
import { Link } from 'react-router-dom';

import logoImg from './assets/img/logo-white.png';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  },
  img: {
    height: '3rem'
  }
});

const Logo = () => {
  const classes = useStyles();

  return (
    <Link to={{ pathname: '/' }}>
      <img src={logoImg} alt="logo" className={classes.img} />
    </Link>
  );
};
const MyAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar
      sx={{
        '& .RaAppBar-title': {
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }
      }}
      {...props}
    >
      <Logo />
      <span className={classes.spacer} />
    </AppBar>
  );
};

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

export default MyLayout;
