import { HydraAdmin } from '@api-platform/admin';
import { createTheme } from '@material-ui/core/styles';
import NotesIcon from '@material-ui/icons/Notes';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React from 'react';
import { Datagrid, List, Resource, TextField } from 'react-admin';
import { Login } from 'react-admin';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import MyLayout from './layout';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT; // Change this by your own entrypoint if you're not using API Platform distribution

const theme = createTheme({
  palette: {
    primary: {
      light: '#0bbf97',
      main: '#007bff',
      dark: '#ffd447',
      contrastText: '#fff'
    },
    secondary: {
      light: '#964ccd',
      main: '#007bff',
      dark: '#ffd447',
      contrastText: '#000'
    }
  }
});

const UserList = props => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    hasEdit={false}
    hasCreate={false}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source={'name'} label={'Name'} sortBy={'name'} />
      <TextField source={'email'} label={'Email'} sortBy={'email'} />
      <TextField
        source={'organizationName'}
        label={'Organization Name'}
        sortable={false}
      />
      <TextField
        source={'countOfProceduresCreated'}
        label={'Created Procedures'}
        sortable={false}
      />
      <TextField
        source={'countOfUsedProcedures'}
        label={'Used Procedures'}
        sortable={false}
      />
      <TextField
        source={'createdAtDateTime'}
        label={'Created'}
        sortBy={'createdAt'}
      />
    </Datagrid>
  </List>
);

const ProcedureList = props => (
  <List
    {...props}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    hasEdit={false}
    hasCreate={false}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source={'name'} label={'Procedure Name'} sortBy={'name'} />
      <TextField
        source={'organizationName'}
        label={'Organization Name'}
        sortable={false}
      />
      <TextField
        source={'createdByName'}
        label={'Created By'}
        sortBy={'createdBy.name'}
      />
      <TextField
        source={'countOfProcedureSteps'}
        label={'Count of Steps'}
        sortable={false}
      />
      <TextField
        source={'countOfUsedProcedures'}
        label={'Times Used'}
        sortable={false}
      />
      <TextField
        source={'latestUpdatedAtOfUsedProcedures'}
        label={'Last Time Used'}
        sortable={false}
      />
      <TextField
        source={'createdAtDateTime'}
        label={'Created At'}
        sortBy={'createdAt'}
      />
      <TextField
        source={'updatedAtDateTime'}
        label={'Updated At'}
        sortBy={'updatedAt'}
      />
    </Datagrid>
  </List>
);

export default () => (
  <HydraAdmin
    authProvider={authProvider}
    entrypoint={entrypoint}
    dataProvider={dataProvider}
    loginPage={Login}
    theme={theme}
    layout={MyLayout}
  >
    <Resource name={'users'} list={UserList} icon={SupervisorAccountIcon} />
    <Resource name={'procedures'} list={ProcedureList} icon={NotesIcon} />
  </HydraAdmin>
);
